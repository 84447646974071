<template>
  <v-app>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <Overlay :overlay="overlay" />
    <CreateIssueDialog
      v-if="dialogCreateIssue == true"
      :dialogCreateIssue="dialogCreateIssue"
      :selectedFilesFromDrop="selectedFilesFromDrop"
      :StoreObj="StoreObj"
      @clicked="dialogCreateIssueEmit"
    />
    <CreateIssueDialogNew
      :dialogCreateIssue="dialogCreateIssueNew"
      :selectedFilesFromDrop="selectedFilesFromDrop"
      @clicked="dialogCreateIssueEmitNew"
    />
    <v-app-bar dense app class="elevation-1" color="white">
      <v-card class="elevation-0" to="/ProjectPage">
        <v-img width="140px" contain src="@/assets/BugTraker.png"></v-img>
      </v-card>
      <v-divider vertical class="ml-4"></v-divider>
      <v-btn
        small
        class="text-capitalize"
        text
        v-for="(route, idx) in project_roles == 'OBSERVER' ? RouterList : RouterList"
        :key="idx"
        @click="checkEachRoute(route)"
      >
        <div :class="route.MenuRoute == $route.name ? 'primary--text' : ''">{{ route.MenuName }}</div></v-btn
      >
      <v-spacer></v-spacer>
      <span class="mx-3">
        <div class="text-center">{{ $store.getters.get_current_project_details.project_name }}</div>
        <div class="FontSize font-weight-bold text-center">{{ project_roles }}</div>
      </span>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="elevation-0" small v-bind="attrs" v-on="on" @click="dialogCreateIssue = true" color="primary">
            <v-icon>mdi-plus</v-icon>
            Report Issue
          </v-btn>
        </template>
        <span>Report An Issue</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="elevation-0" icon @click="htmlToImageMethod()" v-bind="attrs" v-on="on">
            <v-icon color="primary">mdi-information</v-icon>
          </v-btn>
        </template>
        <span>Facing Issues Using BugTrakr ? Click To Report</span>
      </v-tooltip>

      <!-- <v-btn icon to="/ProjectPage" color="primary">
        <v-icon>mdi-chevron-double-left</v-icon>
      </v-btn> -->

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :disabled="overlay == true" v-bind="attrs" v-on="on" to="/ProjectPage" icon color="primary">
            <v-icon>mdi-chevron-double-left</v-icon>
          </v-btn>
        </template>
        <span>Back Button</span>
      </v-tooltip>

      <!-- <v-btn icon to="/" color="primary">
        <v-icon>mdi-logout</v-icon>
      </v-btn> -->

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :disabled="overlay == true" v-bind="attrs" v-on="on" to="/" icon color="primary">
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </template>
        <span>Log Out Button</span>
      </v-tooltip>
    </v-app-bar>
    <v-main>
      <div ref="home">
        <v-card
          class="elevation-0"
          @drop.prevent="onDrop($event)"
          @dragover.prevent="onDragOver($event)"
          @dragenter.prevent="dragover = true"
          @dragleave.prevent="onDragLeave($event)"
        >
          <router-view v-if="dragover == false" />
          <v-card class="elevation-0" v-else :height="Height">
            <v-card fill-height fluid :height="`${Height - 120}`" class="elevation-0 borderDotted ma-8">
              <v-container fill-height fluid>
                <v-row align="center" justify="center">
                  <v-col class="text-center">
                    <v-icon size="60px" color="primary">mdi-paperclip-plus</v-icon>
                    <h2 class="mt-2">Please Drag And Drop An Attachment To Report And Issue</h2>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-card>
        </v-card>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import * as htmlToImage from "html-to-image";
import CreateIssueDialog from "@/components/Issues/Dialogs/CreateIssueDialog.vue";
import CreateIssueDialogNew from "@/components/Extras/CreateIssueDialog.vue";
import RouterList from "@/JsonFiles/RouterList.json";
import Snackbar from "@/components/Extras/Snackbar.vue";
import Overlay from "@/components/Extras/Overlay.vue";
export default {
  components: {
    CreateIssueDialogNew,
    CreateIssueDialog,
    Snackbar,
    Overlay,
  },
  data: () => ({
    StoreObj: {},
    RouterList: [],
    project_roles: "",
    Height: 0,
    overlay: false,
    dragover: false,
    dialogCreateIssue: false,
    dialogCreateIssueNew: false,
    selectedFilesFromDrop: [],
    SnackBarComponent: {},
  }),
  watch: {
    "$route.name"() {
      this.$store.commit("SET_REFRESH", false);
    },
    "$store.getters.get_current_project_details"(value) {
      this.project_roles = value.roles
        .filter((item) => item != "APPROVER")
        .join("/")
        .replaceAll("_", " ");
    },
  },
  mounted() {
    this.$router.push("ProjectDetails");
    this.RouterList = RouterList.map((route) => {
      let object = route;
      if (object.MenuRoute == "ProjectDetails") {
        object.MenuName = this.$store.getters.get_current_project_details.project_display_id;
      }
      return object;
    });
    this.project_roles = this.$store.getters.get_current_project_details.roles
      .filter((item) => item != "APPROVER")
      .join("/")
      .replaceAll("_", " ");
    this.Height = window.innerHeight;
  },
  methods: {
    dialogCreateIssueEmitNew() {
      this.dialogCreateIssueNew = false;
      this.selectedFilesFromDrop = [];
    },
    htmlToImageMethod() {
      this.overlay = true;
      this.$forceUpdate();
      this.selectedFilesFromDrop = [];
      var self = this;
      setTimeout(() => {
        htmlToImage.toBlob(this.$refs.home).then(function (blob) {
          blob.name = `ScreenShot${new Date().getTime()}.png`;
          self.overlay = false;
          self.selectedFilesFromDrop.push(blob);
          // self.dialogCreateIssueNew = true;
          let object = {
            selectedFilesFromDrop: self.selectedFilesFromDrop,
            routeName: self.$route.name,
          };
          self.$store.commit("SET_ISSUE_DETAILS", object);
          self.$router.push("ReportIssue");
        });
      }, 2000);
    },
    onDragLeave() {
      this.dragover = false;
      this.selectedFilesFromDrop = [];
      this.$forceUpdate();
    },
    onDragOver() {
      this.dragover = true;
      this.selectedFilesFromDrop = [];
      this.$forceUpdate();
    },
    onDrop(event) {
      console.log("Check The Event", event.dataTransfer.files);
      this.selectedFilesFromDrop = event.dataTransfer.files;
      this.dialogCreateIssue = true;
      this.$forceUpdate();
    },
    dialogCreateIssueEmit(Toggle) {
      if (Toggle == 2) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: "Issue Reported Successfully",
        };
      }
      this.dialogCreateIssue = false;
      this.selectedFilesFromDrop = [];
      this.dragover = false;
    },
    checkEachRoute(route) {
      if (route.MenuRoute != this.$route.name) {
        this.$router.push(route.MenuRoute);
      }
    },
  },
};
</script>
