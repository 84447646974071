<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog persistent max-width="500px" v-model="dialogCreateIssue">
      <v-overlay :value="overlay">
        <v-progress-circular :rotate="180" :width="5" :value="loaderPercentage" color="white" size="120">
          {{ count + 1 }} /{{ selectedFiles.length }}
        </v-progress-circular>
        <div class="PrimaryFontColorXSmall">Uploading Attachments...</div>
      </v-overlay>
      <v-card>
        <v-toolbar dense dark color="primary" class="elevation-0">
          <div v-text="'Report Issue'"></div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogCreateIssueEmit(1)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pb-0 pt-4">
          <v-form ref="form">
            <!-- <v-row>
              <v-col cols="12" xs="12" sm="12" md="6"> -->
            <div class="FontSize font-weight-bold">Is this a Bug or an Enhancement ?</div>
            <v-radio-group
              dense
              class="pa-0 ma-0"
              v-model="report_issue.issue_type"
              row
              :rules="[(v) => !!v || 'Required']"
            >
              <v-radio label="Bug" value="BUG"></v-radio>
              <v-radio label="Enhancement" value="ENHANCEMENT"></v-radio>
            </v-radio-group>
            <v-textarea
              dense
              outlined
              rows="5"
              :counter="1000"
              class="FontSize field_height field_label_size"
              label="Description *"
              v-model="report_issue.issue_description"
              :rules="[
                (v) => !!v || 'Required',
                (v) => (v && v.length <= 1000) || 'Description must be less than 1000 characters',
              ]"
            ></v-textarea>
            <!-- </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" class="pt-8"> -->
            <v-select
              dense
              outlined
              class="FontSize field_height field_label_size"
              label="Sub-System *"
              :items="sub_systems"
              item-text="text"
              item-value="value"
              :rules="[(v) => !!v || 'Required']"
              v-model="report_issue.issue_reported_platform"
            ></v-select>
            <v-select
              dense
              outlined
              class="FontSize field_height field_label_size"
              label="Severity"
              v-model="report_issue.issue_severity"
              :items="issue_severityItems"
              item-text="text"
              item-value="value"
              :rules="[(v) => !!v || 'Required']"
            ></v-select>
            <v-file-input
              dense
              v-model="selectedFiles"
              color="primary accent-4"
              counter
              multiple
              class="FontSize field_height field_label_size"
              label="Upload Attachments"
              prepend-icon=""
              :rules="[(v) => !!v || 'Required']"
              prepend-inner-icon="mdi-paperclip"
              outlined
              :show-size="1000"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip x-small v-if="selectedFiles.length != 0 && index < 1" color="primary" dark label small>
                  <div class="FontSizeSmall">
                    {{
                      !selectedFilesFromDrop || selectedFilesFromDrop.length == 0
                        ? text.slice(0, 20)
                        : selectedFiles[0].name.slice(0, 20)
                    }}
                  </div>
                </v-chip>
                <span v-else-if="index === 1" class="text-overline grey--text text--darken-3 mx-2">
                  +{{ selectedFiles.length - 1 }} File(s)
                </span>
              </template>
            </v-file-input>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0 pr-6 pb-4">
          <v-spacer></v-spacer>
          <v-btn small outlined color="primary" @click="dialogCreateIssueEmit(1)">Cancel</v-btn>
          <v-btn small color="primary" :loading="loading" @click="validateMethod()">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
var AWS = require("aws-sdk");
import { reportIssue } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    selectedFilesFromDrop: FileList,
    dialogCreateIssue: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    count: 0,
    overlay: false,
    report_issue: {
      issue_type: "",
      issue_module_name: "",
      issue_sub_module_name: "",
      issue_description: "",
      assign_to: "",
      issue_severity: "",
      issue_reported_platform: "",
      issue_reported_project_version: "",
    },
    loading: false,
    SnackBarComponent: {},
    selectedFiles: [],
    sub_systems: [],
    issue_attachments: [],
    issue_severityItems: [
      {
        text: "Not Specified",
        value: "NOT_SPECIFIED",
      },
      {
        text: "High",
        value: "HIGH",
      },
      {
        text: "Medium",
        value: "MEDIUM",
      },
      {
        text: "Low",
        value: "LOW",
      },
    ],
    loaderPercentage: 0,
    loaderPercentageDivider: 0,
  }),
  watch: {
    "report_issue.issue_module_name"(value) {
      this.report_issue.issue_description = value;
    },
  },
  mounted() {
    this.selectedFiles = [];
    if (this.$store.getters.get_current_project_details.sub_systems.length != 0) {
      this.sub_systems = this.$store.getters.get_current_project_details.sub_systems.map((subsystem) => {
        let object = {};
        object.text = subsystem.project_platform;
        object.value = subsystem.project_platform;
        return object;
      });
    }
    this.sub_systems.push({
      text: "Not Specified",
      value: "NOT_SPECIFIED",
    });
    this.selectedFiles = [];
    this.report_issue.issue_reported_platform = "NOT_SPECIFIED";
    this.report_issue.issue_severity = "NOT_SPECIFIED";
    console.log("Check selectedFilesFromDrop", this.selectedFilesFromDrop);
    if (this.selectedFilesFromDrop && this.selectedFilesFromDrop.length != 0) {
      this.selectedFiles = this.selectedFilesFromDrop;
    }
  },
  methods: {
    validateMethod() {
      if (this.$refs.form.validate()) {
        if (this.selectedFiles.length != 0) {
          this.count = 0;
          this.loaderPercentage = 0;
          this.loaderPercentageDivider = 100 / this.selectedFiles.length;
          this.uploadImageToS3();
        } else {
          this.reportIssueMethod();
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Invalid Input(s)",
        };
      }
    },
    async uploadImageToS3() {
      this.loading = true;
      this.overlay = true;
      let s3Credentials = this.$store.getters.get_current_user_details.s3_details;
      this.loading = true;
      var self = this;
      var s3Bucket = new AWS.S3({
        region: s3Credentials.region,
        accessKeyId: s3Credentials.access_key,
        secretAccessKey: s3Credentials.secret_key,
      });
      var fileReader = new FileReader();
      fileReader.readAsDataURL(self.selectedFiles[self.count]);
      fileReader.onload = async function () {
        var base64FileContent = fileReader.result.split(",");
        var buf = Buffer.from(base64FileContent[1], "base64");
        var param = {
          Bucket: s3Credentials.bucket_name,
          Key: `BugTrakr/Issues/Attachments/${new Date().getTime()}_${self.selectedFiles[self.count].name}`,
          Body: buf,
          ContentType: self.selectedFiles.type,
          ACL: "public-read-write",
        };
        await s3Bucket.putObject(param, function (err, data) {
          if (err) {
            console.log(err);
            self.loading = false;
            self.overlay = true;
          } else if (data) {
            if (self.count != self.selectedFiles.length - 1) {
              self.count += 1;
              self.issue_attachments.push(
                `https://${param.Bucket}.s3.ap-southeast-1.amazonaws.com/${encodeURI(param.Key)}`
              );
              self.uploadImageToS3();
              self.loaderPercentage += self.loaderPercentageDivider;
            } else {
              self.issue_attachments.push(
                `https://${param.Bucket}.s3.ap-southeast-1.amazonaws.com/${encodeURI(param.Key)}`
              );
              self.reportIssueMethod(self.issue_attachments);
              self.loaderPercentage += 100;
            }
          }
        });
      };
    },
    async reportIssueMethod(issue_attachments) {
      var mutationname = reportIssue;
      var mutationresult = "ReportIssue";
      var inputParams = {
        user_email_id: this.$store.getters.get_user_email,
        project_id: this.$store.getters.get_current_project_details.project_id,
        issue_type: this.report_issue.issue_type,
        issue_module_name: this.report_issue.issue_description.slice(0, 100),
        issue_description: this.report_issue.issue_description,
        issue_severity: this.report_issue.issue_severity,
        issue_attachments: issue_attachments ? issue_attachments : undefined,
        issue_reported_platform:
          this.report_issue.issue_reported_platform && this.report_issue.issue_reported_platform != ""
            ? this.report_issue.issue_reported_platform
            : undefined,
        issue_reported_project_version:
          this.report_issue.issue_reported_project_version && this.report_issue.issue_reported_project_version != ""
            ? this.report_issue.issue_reported_project_version
            : undefined,
      };
      try {
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(mutationname, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data[mutationresult]);
        if (ResultObject.status == "Success") {
          // console.log("sanckbar is working properlly"),
          //   (this.SnackBarComponent = {
          //     SnackbarVmodel: true,
          //     SnackbarColor: "green",
          //     Top: true,
          //     SnackbarText: ResultObject.message,
          //   });
          // setTimeout(() => {
          //   this.dialogCreateIssueEmit((this.Toggle = 2));
          // }, 500);
          this.dialogCreateIssueEmit((this.Toggle = 2));
        }
        this.loading = false;
        this.overlay = false;
      } catch (error) {
        console.log("Check Error", error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.loading = false;
        this.overlay = false;
      }
    },
    dialogCreateIssueEmit(Toggle) {
      this.loading = false;
      this.selectedFiles = [];
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
